import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';

export default ({ data }) => (
    <Layout>
        <div>
        <h1>Über uns {data.site.siteMetadata.title}</h1>
            <p>Es ist noch Platz für einen etwas anderen Lego News Blog</p>
        </div>
    </Layout>
)

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;